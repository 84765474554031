<template>
  <v-container>
    <v-card outlined class="mb-8 my-sm-16">
      <v-card-title class="justify-center primary--text text-bold">Terms of Use</v-card-title>
      <v-card-text>
        <div id="terms-nav">
          <h4>
            <ul>
              <li><a href="#generalTerms">General Terms</a></li>
              <li><a href="#customer">Customer Terms</a></li>
              <li><a href="#promoter">Promoter Terms</a></li>
            </ul>
          </h4>
        </div>
        <div class="terms-block" id="generalTerms">
          <v-card-title class="px-0 justify-center  primary--text"
            >Ticket Text Limited General Terms &amp; Conditions</v-card-title
          >
          <p>
            These Terms set out important information about Ticket Text’s website www.tickettext.co.uk (“Website”) and
            Your use of and access to the Website.
          </p>
          <p>
            By using this Website, You agree to accept these Terms. If You do not, You should not use the Website and
            exit.
          </p>
          <ul>
            <li>
              <v-card-subtitle class="px-0 primary--text">About Ticket Text and the use of the Website</v-card-subtitle>
            </li>
            <li>
              1.1. The Website is provided to You for Your personal use. It is intended to be a useful source of
              information about events, festivals, concerts or other productions (Events).
            </li>
            <li>
              1.2. Ticket Text is a ticket agency and consequently we do not promote, host nor create the Events that
              are listed on the Website. We do not control these Events and we do not set their prices. We only charge
              our booking fee on top of the face value of the ticket. Therefore, as outlined in these Terms, we do not
              accept any other liability for any tickets purchased via our Website.
            </li>
            <li>
              1.3. The Website is owned and operated by Ticket Text Limited whose registered address is 2 Northdown Street, London, N1 9BG.
            </li>
          </ul>
          <ul>
            <li><v-card-subtitle class="px-0 primary--text">Intellectual Property</v-card-subtitle></li>
            <li>
              2.1. All content and materials on the Website are copyrighted and are protected under treaty provisions,
              worldwide copyright laws, trademark law, database rights and all other intellectual property rights.
            </li>
            <li>
              2.2. Ticket Text’s materials may not be reproduced, copied, edited, published, transmitted or uploaded in
              any way without written permission from Ticket Text. Unless expressly stated in the limited license
              provision of these Terms, we do not grant any implied or express right to you under any of its copyrights,
              trademarks, or other proprietary information.
            </li>
            <li>
              2.3. A user will have their use terminated by us if they use the Website to unlawfully transmit
              copyrighted material without a valid defence, fair use exemption or a license expressing consent by us.
            </li>
            <li>
              2.4. Any user who submits or uploads material to the Website, including, without limitation, images, text,
              articles, graphics, video, audio or any other copyrightable material, must ensure that the material that
              they are uploading does not infringe on any copyrights or any other third party rights.
            </li>
            <li>
              2.4.1. By uploading this Material You irrevocably and unconditionally grant Ticket Text a non-exclusive,
              worldwide, royalty-free licence. This includes the right for Ticket Text to sub-license the entire rights,
              title and interest in such uploaded Material so that we and any heir or successor may, subject to these
              Terms, use the Material in any way, including the right to copy, edit, adapt reformat, translate, create
              derivative works from, without limitation and to incorporate it into other work, distribute, perform,
              play, broadcast, use for commercial purposes and otherwise make it available to the public, either in
              whole or in part, or by copies, in any format or medium known now or which may be developed in the future,
              for the full period during which such rights subsist.
            </li>
            <li>
              2.4.2. You will remain the owner of the copyright of any original Material that you submit or upload to
              the Website.
            </li>
            <li>
              2.4.3. The above includes all renewals, revivals, reversions and extensions of the same and thereafter to
              the fullest extent possible in perpetuity.
            </li>
            <li>
              2.4.4. The permission You have granted to us is non-exclusive and You may continue to use Materials in any
              way You see fit, including allowing others to use it, so long as such use in no way interferes with or
              impairs the rights You have granted to Ticket Text, its heirs or successors.
            </li>
          </ul>
          <ul>
            <li><v-card-subtitle class="px-0 primary--text">Availability of the website</v-card-subtitle></li>
            <li>
              3.1. We cannot guarantee or promise that this Website will be error free, uninterrupted or virus free.
            </li>
            <li>
              3.2. If a fault or error does occur with the Website, you should report it to support@tickettext.co.uk and
              we will endeavour to correct the issue as soon as we can.
            </li>
            <li>
              3.3. Your access to the Website may be restricted to allow for maintenance, repairs or for introducing new
              content to the Website. Access will be restored as soon as reasonably possible.
            </li>
            <li>
              3.4. Ticket Text reserves the right to suspend, at its discretion, the whole or part of the Website for
              any reason whatsoever. In this case, Ticket Text will aim to, but not be obliged to, give the user as much
              notice as reasonably possible. Ticket Text will not however be liable to the user for any loss whatsoever
              arising from such a suspension.
            </li>
          </ul>
          <ul>
            <li><v-card-subtitle class="px-0 primary--text">Liability</v-card-subtitle></li>
            <li>
              4.1. The Website does provide content from other websites or resources and while we endeavour to make sure
              that materials included on the Website are sound, of a high quality and correct, we cannot guarantee the
              content nor make any warranties in relation to it.
            </li>
            <li>
              4.2. If we are in breach of these Terms, we will only be responsible for any losses suffered by you as a
              result and to the extent that they are a foreseeable consequence of both us and You at the time you used
              the Website. Further, in any event, our liability will not include business losses such as lost data, loss
              of profit or interruption of business.
            </li>
            <li>
              4.3. Clause 4 shall not limit or affect our liability if something we do negligently causes death or
              personal injury.
            </li>
          </ul>
          <ul>
            <li><v-card-subtitle class="px-0 primary--text">General</v-card-subtitle></li>
            <li>
              5.1. If these Terms are not complied with, we reserve the right to terminate Your access and use of the
              site
            </li>
            <li>
              5.2. These Terms represent the entire agreement and understanding between You and us. This will have
              priority over any and all other statements, agreements or understandings whether in writing or verbally.
            </li>
            <li>
              5.3. From time to time we may modify these Terms and such changes and modifications shall be effective
              immediately upon them being posted to the Website. Your continued use and access to the Website shall be
              deemed as Your acceptance to the new modified Terms.
            </li>
            <li>
              5.4. We may collect information about You. For more information about this please refer to our Privacy
              Policy.
            </li>
            <li>
              5.5. These Terms will be subject to the laws of England and Wales. If You want to take court proceedings,
              You must do so within the United Kingdom.
            </li>
            <li>
              5.6. At no time do we promise that the material or content on the Website will be appropriate or available
              for use outside of the United Kingdom. It is prohibited to access the Website from territories where the
              content or material is illegal or unlawful. If You do choose to access the Website from outside the United
              Kingdom, You do so at Your own risk and You are responsible for compliance with local law.
            </li>
            <li>
              5.7. The Website does contain links to other websites, which are independent of Ticket Text, and
              accordingly we have no control over these sites. Any and all links that are provided are provided as a
              convenience to you as a user of this Website. Such websites do not imply our endorsement and we do not
              imply an association with their operators.
            </li>
            <li>
              5.8. If you have any questions or queries please contact us at
              <a href="mailto:info@tickettext.co.uk">info@tickettext.co.uk</a>.
            </li>
          </ul>
        </div>
        <div class="terms-block" id="customer">
          <v-card-title class="px-0 justify-center  primary--text">
            Ticket Text Limited Customer Terms &amp; Conditions
          </v-card-title>
          <ul>
            <li><v-card-subtitle class="px-0 primary--text">Information about us</v-card-subtitle></li>
            <li>
              1.1. www.tickettext.co.uk is a website operated by Ticket Text Limited trading as Ticket Text (Ticket
              Text, we, us or our).
            </li>
            <li>
              1.2. Ticket Text Limited is a registered company in England and Wales with company number 08294030 whose
              registered address is at Mason’s Yard, 34 High Street, London, SW19 5BY.
            </li>
          </ul>
          <ul>
            <li><v-card-subtitle class="px-0 primary--text">General</v-card-subtitle></li>
            <li>
              2.1. The Ticket Text Website is only intended for the use of residents of the United Kingdom and we do not
              accept orders from individuals outside those countries.
            </li>
            <li>
              2.2. Your status:
              <ul>
                <li>
                  2.2.1. By placing an order and purchasing a ticket through our Website, you warrant to us that you are
                  legally capable of entering into a binding contract.
                </li>
                <li>2.2.2. You are over the age 18 years old and have a valid debit or credit card.</li>
              </ul>
            </li>
          </ul>
          <ul>
            <li><v-card-subtitle class="px-0 primary--text">Formation of the contract</v-card-subtitle></li>
            <li>
              3.1. By placing an order and purchasing a ticket, You are instituting an offer to us to buy tickets.
            </li>
            <li>3.2. This does not mean that Your order has been accepted.</li>
            <li>
              3.3. You will receive an email and/or text message confirming the acceptance of Your order including Your
              ticket and barcode.
            </li>
            <li>3.4. All orders are subject to acceptance by us and acceptance by the third party supplier.</li>
          </ul>
          <ul>
            <li><v-card-subtitle class="px-0 primary--text">Your obligations</v-card-subtitle></li>
            <li>
              4.1. General
              <ul>
                <li>
                  4.1.1. You represent and warrant that the tickets You purchase are for personal use only and have not
                  been purchased as part of, or in any form of business or commercial activity.
                </li>
                <li>
                  4.1.2. You represent and warrant that the tickets may not be resold or offered for resale by anyone.
                  Be it for a premium or for any other transaction.
                </li>
                <li>
                  4.1.3. The resale or attempted resale of any ticket will be grounds for cancellation of the tickets
                  without a refund or any compensation.
                </li>
                <li>
                  4.1.4. You are responsible for informing Ticket Text of any change of address, contact telephone
                  number or email address both before and after receipt of receipt of your tickets. Changes can be made
                  by logging into Your account.
                </li>
                <li>
                  4.1.5. It is Your responsibility to check your ticket details, as mistakes cannot always be rectified.
                </li>
              </ul>
            </li>
            <li>
              4.2. The event and venue
              <ul>
                <li>
                  4.2.1. It is your responsibility to check if the event has been rearranged in any way. This may
                  include change of date and/or time as well as change of venue. If the event is cancelled or postponed,
                  you will be offered Tickets for the rescheduled date and time. Once we are notified that an event has
                  been cancelled or there is a significant change by the third party supplier, we will do our best to
                  inform you using the contact details you provided to us when you placed your order. We cannot however
                  guarantee that we will be able to make contact with you.
                </li>
                <li>
                  4.2.2. You are responsible for arriving at the event on time. The third party supplier will state last
                  entry times. Every effort will be made to admit those who arrive late, but this admission is by no
                  means guaranteed.
                </li>
                <li>
                  4.2.3. While attending the event, it is Your responsibility to comply with all regulations set in
                  place at the event and venue. These include, but are not limited to, age restrictions and safety
                  announcement.
                </li>
                <li>
                  4.2.4. The venue and/or the third party supplier reserve the right to refuse admission if in their
                  reasonable opinion your admission to the venue might: be a risk to the safety of other event goers,
                  affect the enjoyment of other event goers, affect the running of the event.
                </li>
                <li>
                  4.2.5. In addition to 6.2.4. the venue and/or the third party supplier reserve the right to refuse
                  admission to the venue if Your behaviour appears to be threatening or aggressive, and/or You appear to
                  be intoxicated and/or under the influence of drugs.
                </li>
                <li>Should any of the above occur, you will not be entitled to a refund.</li>
              </ul>
            </li>
            <li>
              4.3. Miscellaneous
              <ul>
                <li>
                  4.3.1. The unauthorised use of photographic and recording equipment is prohibited at any time during
                  the event. Any images, videos, files or other medium may be destroyed.
                </li>
                <li>
                  4.3.2. We, the venue and the third party supplier have no responsibility for the damage, loss or theft
                  of any personal property.
                </li>
                <li>
                  4.3.3. By attending the event, you consent to being photographed, filmed and sound recorded as a
                  member of the audience.
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <li><v-card-subtitle class="px-0 primary--text">Purchasing tickets</v-card-subtitle></li>
            <li>
              5.1. We sell tickets on behalf of third party suppliers which include promoters, bands, venues and
              similar. We therefore do not set the ticket price and accordingly have no control over the price set.
            </li>
            <li>5.2. All prices shown at the time of payment are inclusive of VAT.</li>
            <li>5.3. All payments on the Website must be made with a valid credit or debit card or by using PayPal.</li>
            <li>5.4. We may charge a Booking Fee on every ticket purchased. This Fee:</li>
            <li>
              5.4.1. Covers Ticket Text’s costs for processing the transaction which includes, but is not limited to,
              the fees for processing the card transaction, VAT and other taxes, customer service costs and profit.
            </li>
            <li>
              5.4.2. Covers the costs for providing the Website that makes the ticket available to you and the third
              party supplier.
            </li>
            <li>5.5. Prices are liable to change at any time. These changes will not affect orders already placed.</li>
            <li>
              5.6. There may be a restriction on the maximum number of tickets that may be purchased per person or per
              debit/credit card. We will do our best to make this clear on the Website but we reserve the right the
              right to cancel, without notice, any orders that exceed the authorised maximum.
            </li>
            <li>
              5.7. The Website contains a large quantity of tickets and Events and as such, there is always the
              possibility that despite our best efforts, tickets may be incorrectly priced. As part of our approval
              process, we will usually verify price, however when an error has been made, we reserve the right to cancel
              or reject your order and will notify you of such action. You will then be able to reorder at the correct
              price.
            </li>
            <li>
              5.8. If the venue or third party supplier requires is to reduce an allocation or if we have oversold an
              event due to human error, we reserve the right to cancel tickets. In this case, You will be provided with
              a full refund.
            </li>
            <li>5.9. Ticket delivery</li>
            <li>
              5.9.1. When purchasing Your ticket, You will be able to select Your method of delivery. Either email
              and/or text.
            </li>
            <li>5.9.2. You will be also be able to retrieve Your ticket and resend it by logging into Your account.</li>
          </ul>
          <ul>
            <li><v-card-subtitle class="px-0 primary--text">Refund policy</v-card-subtitle></li>
            <li>
              6.1. Unfortunately Tickets cannot be refunded, exchanged or modified after their purchase unless the event
              is cancelled.
            </li>
            <li>
              6.2. Further to 4.2.1. if an Event has been cancelled or postponed, subject to availability, You will be offered Tickets to the rescheduled event up to the face value previously paid, or if You are unable to attend the rescheduled Event, or the Event is cancelled, a refund will be issued. Refund requests should be sent to <a href="mailto:info@tickettext.co.uk">info@tickettext.co.uk</a> within 14 days of the cancelled event. Requests made after this time will not be accepted. Ticket Text Limited is not liable in any way for refunds or for any resulting costs you may incur for travel, accommodation, any other related goods or service or other compensation.
            </li>
            <li>
              6.3. The third party supplier reserves the right to alter the programme of their Event without being
              obliged to offer refunds or exchange tickets.
            </li>
            <li>
              6.4. In the event of a refund, Fees associated with the order are non-refundable. These Fees include all
              booking fees, surcharges and other associated fees charged by Ticket Text on top of the face value of the
              ticket.
            </li>
            <li>
              6.5. Refunds will be issued to the original purchaser or back onto the credit or debit card used in the
              original transaction.
            </li>
          </ul>
          <ul>
            <li><v-card-subtitle class="px-0 primary--text">Limitation of liability</v-card-subtitle></li>
            <li>
              7.1. In the event that Ticket Text incurs any liability whatsoever to You for breaking this contract, such
              liability will, in all cases, be limited to the amount you paid for the Ticket You purchased.
            </li>
            <li>
              7.2. Neither Ticket Text, its directors, employees, contract workers or other representatives will be
              liable for any losses or damages that arise out of, or in connection with use of the Website and Service
              we provide.
            </li>
            <li>7.3. Ticket Text’s limitation of liability applies to damages of any kind.</li>
            <li>
              7.3.1. This includes but is not limited to, damages both direct and indirect, loss of data, loss of income
              or profit, loss of, or damage to property and claims by third parties. Whether arising from negligence,
              breach of contract, statutory duty or otherwise.
            </li>
            <li>
              7.4. Notwithstanding the foregoing, none of the exclusions or limitations in this clause 7 are intended to
              limit any rights You may have under Section 2(3) of the Consumer Protection Act 1987. Nothing in this may
              be taken as meaning to be excluded, nor in any way exclude or limit Ticket Text’s liability to You for
              death or personal injury resulting from Ticket Text’s negligence or that of Ticket Text’s employees or
              agents.
            </li>
            <li>
              7.5. Third party suppliers may cancel Events for a variety of reasons and we will therefore not be
              responsible for any postponements or cancellations.
            </li>
            <li>
              7.6. Ticket Text is not responsible in tort or in contract for the unauthorised access to, alteration,
              theft or destruction of emails, files, programs, or information of You by any person through accident or
              by fraudulent means or devices. Even if such access occurs as a result of Ticket Text’s own negligence.
            </li>
          </ul>
          <ul>
            <li><v-card-subtitle class="px-0 primary--text">Force majeure</v-card-subtitle></li>
            <li>
              8.1. Ticket Text will not be responsible for any failure or delay of the Website or Service provided by us
              due to occurrences commonly known as Force Majeure.
            </li>
            <li>
              8.2. Force Majeure includes but is not limited to, embargoes, strikes, riots, war, terrorism or threats of
              terrorism, attacks, lock-outs, civil commotion, or other concerted acts of workmen, causalities or
              accidents, or any other causes or circumstances beyond Ticket Text’s control that prevent or thwart the
              performance provided by us and any of its obligations hereunder.
            </li>
            <li>
              8.3. Further to 10.2. Force Majeure includes but is not limited to, fire, explosion, earthquake, storm,
              flood, epidemic or other natural disaster, as well as the impossibility of use of public or private
              transport, the use of motor transport, shipping or aviation and the impossibility of the use of public or
              private telecommunication networks.
            </li>
            <li>
              8.4. For the entire period that Force Majeure continues, our performance under all Terms will be deemed
              suspended. During that period, we will have an extension of time for the performance of our Website and
              Service. We will use reasonable efforts to bring the Force Majeure event to a close or find a solution to
              which our obligations under these Terms may be performed despite the Force Majeure.
            </li>
          </ul>
          <ul>
            <li><v-card-subtitle class="px-0 primary--text">Waiver</v-card-subtitle></li>
            <li>
              9.1. The failure or neglect by us at any time to enforce these Terms will not be interpreted as, nor shall
              be seen as, a waiver of Ticket Text’s rights hereunder nor in any way affect the validity of the whole or
              any part of these Terms. Nor shall it prejudice Ticket Text’s rights to take subsequent action.
            </li>
          </ul>
          <ul>
            <li><v-card-subtitle class="px-0 primary--text">Entirety of the Terms</v-card-subtitle></li>
            <li>
              10.1. These terms and conditions combined with the other Terms by Ticket Text represent the entire
              agreement between us in relation to the subject matter of any contract and supersede any prior agreement,
              understanding or arrangement between us, whether in writing or verbally
            </li>
            <li>
              10.2. We each acknowledge that by entering into a contract, neither of us have relied on any
              representation, undertaking or promise given by the other or be implied from anything said or written in
              negotiations between us prior to such contract except as expressly stated in these terms and conditions.
            </li>
          </ul>
        </div>
        <div class="terms-block" id="promoter">
          <v-card-title class="px-0 justify-center  primary--text">
            Ticket Text Limited Promoter Terms &amp; Conditions
          </v-card-title>
          <p>
            These terms and conditions (Terms) will automatically become binding as soon as an organiser, promoter or
            third party supplier (You) creates an account on www.tickettext.co.uk (the “Website”) with Ticket Text
            Limited.
          </p>
          <p>
            If You do not agree to these Terms, You may not use or access any of the promoter features or services
            provided by Ticket Text Limited (Service).
          </p>
          <ul>
            <li><v-card-subtitle class="px-0 primary--text">Information about us</v-card-subtitle></li>
            <li>
              1.1. The Service including the Website are provided by Ticket Text Limited (Ticket Text, we, us or our).
            </li>
            <li>
              1.2. Ticket Text Limited is a registered company in England and Wales with company number 08294030 whose
              registered address is at Mason’s Yard, 34 High Street, London, SW19 5BY.
            </li>
            <li>1.3. Our VAT number is: GB 154 2513 33.</li>
          </ul>
          <ul>
            <li><v-card-subtitle class="px-0 primary--text">The Service</v-card-subtitle></li>
            <li>2.1. General</li>
            <li>
              2.1.1. Ticket Text Limited provides You a self-serve platform to allow You to upload and publish Your
              event to our Website.
            </li>
            <li>
              2.1.2. The Service will also provide You tools that enable You to manage Your Events including the
              content, images, ticket tiers and pricing.
            </li>
            <li>2.2. Appointment</li>
            <li>
              2.2.1. We are to act as an online agent for the sale, promotion and advertisement of Your event(s) as
              appointed and authorised by You.
            </li>
            <li>2.3. Promoter account</li>
            <li>
              2.3.1. In order to use our Service, You must register for a promoter account. All details must be
              completed including a valid email address.
            </li>
            <li>
              2.3.2. Your email address and password should be used by You only and You are responsible for all activity
              and actions under Your email address and password.
            </li>
            <li>
              2.3.3. You shall not directly or indirectly allow anyone else to use Your login details. You must make
              every effort to keep Your password safe.
            </li>
            <li>
              2.3.4. The Service is a self-service platform that allows You to list Your events and put them on sale,
              monitor the pricing structure of tickets, allocations and sales.
            </li>
            <li>
              2.3.5. We are not responsible for mistakes made by You, which include, but are not limited to, the
              incorrect number of tickets allocated, errors in pricing or for tickets not going on sale due to incorrect
              use of the Service.
            </li>
            <li>2.3.6. We will provide full technical support when needed between 9am and 5pm, Monday to Friday.</li>
            <li><v-card-subtitle class="px-0 primary--text">Listing Events</v-card-subtitle></li>
            <li>
              3.1. You must ensure that You have all rights and permissions to the content in Your listing with us.
              Further, that we have the necessary right to use the content on our Website.
            </li>
            <li>
              3.2. It is Your responsibility to ensure that the content of Your listing and all its details are
              factually correct. We will not be responsible for any mistakes or errors made by You.
            </li>
            <li>3.3. You must:</li>
            <li>3.3.1. Not provide any information that may be deemed as misleading, untrue or incorrect.</li>
            <li>
              3.3.2. Not upload any content that is offensive, indecent, abusive, distasteful, obscene, defamatory or
              containing pornography.
            </li>
            <li>
              3.3.3. Ensure that Your content does not breach or infringe on any third party intellectual property
              rights. Including but not limited to copyright, trademark, or patents.
            </li>
            <li>3.3.4. Ensure that Your listing contains the correct spelling and grammar.</li>
            <li>
              3.4. Your Event must be a real event based in the United Kingdom and You are only permitted to list this
              Event once.
            </li>
            <li>
              3.5. When You create Your first Event using our Service, Your Event will be pending as it passes through
              our vetting process and will be listed within one working day upon approval. All of Your future Events by
              You will then be listed immediately.
            </li>
            <li>
              3.6. Breaching these Terms may result in Your account being suspended and/or terminated. Further, we
              reserve the right to remove or suspend Events that we deem to be in breach of these Terms.
            </li>
            <li><v-card-subtitle class="px-0 primary--text">Sale Of Tickets</v-card-subtitle></li>
            <li>
              4.1. Ticket Text will apply fees to each ticket. The majority of the time, this will take the form of a
              booking fee. This fee will be paid by the customer and wholly retained by us unless previously agreed
              otherwise.
            </li>
            <li>
              4.2. We will charge the customer the face value of the ticket that is set by You, and the additional
              booking fee per ticket applied by us.
            </li>
            <li>
              4.3. Unless agreed otherwise, the booking fee charged to the customer will be 10% of the ticket’s face
              value if above £10 and a flat £1 for anything under £10. We reserve the right to alter these fees on a
              per-event or per-ticket basis.
            </li>
            <li>4.4. All ticket sales are final and we will not issue any refunds.</li>
            <li>4.5. Refunds:</li>
            <li>
              4.5.1. Refunds will only be issued if an Event is cancelled or the Event is postponed and the Customer is
              unable to attend.
            </li>
            <li>
              4.5.2. You must inform us in writing, in reasonable time before the Event, if You intend to cancel or
              postpone the Event.
            </li>
            <li>
              4.5.3. We reserve the right to charge You an administrative fee for processing refunds to customers if You
              cancel an Event.
            </li>
            <li>4.5.4. Refunds will be for the face value of the Ticket.</li>
            <li>4.5.5. We will not refund any booking fees in any case.</li>
            <li>
              4.5.6. Refunds will also be issued if we acknowledge there has been a failure in the Service provided by
              us.
            </li>
            <li>
              4.5.7. To issue a refund if an Event is cancelled, we will notify customers using the information they
              provided to us. We will only issue refunds if the customer’s monies have not yet been transferred to You.
              We have no obligation to refund customers once this transfer has been made. It then becomes Your
              responsibility to provide the refund. However if You transfer the monies back to us, we will process the
              refunds.
            </li>
            <li><v-card-subtitle class="px-0 primary--text">Ticket Delivery</v-card-subtitle></li>
            <li>
              5.1. All tickets from Ticket Text are e-tickets (electronic tickets). We do not provide paper tickets.
            </li>
            <li>5.2. We deliver our Tickets by email with the additional option to receive the ticket by SMS.</li>
            <li>
              5.3. We reserve the right to place advertising content on our Tickets and any subsequent income generated
              belongs to Ticket Text.
            </li>
            <li><v-card-subtitle class="px-0 primary--text">Intellectual Property</v-card-subtitle></li>
            <li>6.1. Uploading Material to the Website:</li>
            <li>
              6.1.1. You will remain the owner of the copyright of any original Material that You submit or upload to
              the Website.
            </li>
            <li>
              6.1.2. By uploading this Material You irrevocably and unconditionally grant Ticket Text a non-exclusive,
              worldwide, royalty-free licence. This includes the right for Ticket Text to sub-license the entire rights,
              title and interest in such uploaded Material so that we and any heir or successor may, subject to these
              Terms, use the Material in any way, including the right to copy, edit, adapt reformat, translate, create
              derivative works from, without limitation and to incorporate it into other work, distribute, perform,
              play, broadcast, use for commercial purposes and otherwise make it available to the public, either in
              whole or in part, or by copies, in any format or medium known now or which may be developed in the future,
              for the full period during which such rights subsist.
            </li>
            <li>
              6.1.3. The above includes all renewals, revivals, reversions and extensions of the same and thereafter to
              the fullest extent possible in perpetuity.
            </li>
            <li>
              6.1.4. The permission You have granted to us is non-exclusive and You may continue to use Materials in any
              way You see fit, including allowing others to use it, so long as such use in no way interferes with or
              impairs the rights You have granted to Ticket Text, its heirs or successors.
            </li>
            <li><v-card-subtitle class="px-0 primary--text">Receipt Of Funds</v-card-subtitle></li>
            <li>
              7.1. We will collect the face value of the ticket on Your behalf and these funds will be remitted to You
              within ten working days after the event.
            </li>
            <li>7.2. Funds will be transferred via BACS to the bank account nominated in Your promoter account.</li>
            <li>
              7.3. It is Your responsibility to ensure that we hold valid and up-to-date payment details for You. We
              will not be responsible or held liable if payment is made to an incorrect account provided by You.
            </li>
            <li>7.4. Any change in payment details must be made in good time before Your Event takes place.</li>
            <li>7.5. Chargebacks:</li>
            <li>
              7.5.1. We work closely with our payment service provider and merchant bank to prevent chargebacks by
              having layers of security in our payment system. To help detect fraudulent activity, we have anti-fraud
              checks in place as well as screening systems.
            </li>
            <li>
              7.5.2. We have and always will work closely with You and financial institutions to challenge any
              chargeback claims encountered.
            </li>
            <li>
              7.5.3. If a chargeback results against us from one of Your Events, You will be liable and will indemnify
              Ticket Text against any losses that result from the case.
            </li>
            <li>
              7.5.4. If a chargeback is made occurring Ticket Text, we will raise an invoice to You for the associated
              fees and will require payment within three working days. If we are still holding ticket monies that are
              due to be transferred to You, we reserve the right to deduct the associated chargeback fees from the
              total.
            </li>
            <li>
              7.5.5. Any outstanding or unpaid invoices to Ticket Text may result in ticket monies not being transferred
              to You and/or the subsequent amount owed being deducted from the total due to be transferred.
            </li>
            <li><v-card-subtitle class="px-0 primary--text">General Terms</v-card-subtitle></li>
            <li>
              8.1. When using the Services provided by Ticket Text, You shall include our URL and logo’s on all
              advertising material. A logo pack including all info will be available to download.
            </li>
            <li>
              8.2. Our Services are not available and may not be used by anyone under the age of 18 years old. Should we
              find out that You are underage, we reserve the right to temporarily or permanently suspend the Services
              provided by us.
            </li>
            <li>
              8.3. When registering for Your account, we may verify the accuracy of the information You provide to us.
            </li>
            <li>
              8.4. Ticket Text is not responsible for any payment of account of VAT or other taxes on the face value of
              any ticket sales sold through any of its channels.
            </li>
            <li>
              8.5. By agreeing to these Terms, You confirming to us that You are associated with the Event or Venue and
              that You have the sufficient authority to promote and advertise the Event and list the Event with us.
            </li>
            <li>
              8.6. If registering on behalf of a company or business entity, by agreeing, You are confirming to us that
              You have the authority to bind that company or business entity to these Terms.
            </li>
            <li>
              8.7. We are not responsible for any promotion or advertising You do. Do not advertise or promote in any
              way that is illegal, including but not limited to flyposting. You will indemnify us of any fines/costs or
              losses we may incur as a result of illegal methods being used by You.
            </li>
          </ul>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  mounted() {
    window.addEventListener('hashchange', function() {
      window.scrollTo(window.scrollX, window.scrollY - 128);
    });
  },
};
</script>
